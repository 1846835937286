import ophan from './core.js';
import transmit from './transmit.js';

/**
 * Finds the closest ancestor anchor (`<a>`) element of the given element.
 *
 * @param {Element} el - The element to start searching from.
 * @returns {Element|null} The closest ancestor anchor element or null if none found.
 */
export const validAncestorAnchorElement = (el) => {
	if (!el || el.nodeName?.toLowerCase() === 'body') return null;
	return el.nodeName.toLowerCase() === 'a'
		? el
		: validAncestorAnchorElement(el.parentNode);
};

/**
 * Retrieves the value of the `data-component` attribute from the closest ancestor.
 *
 * @param {Element} el - The element to start searching from.
 * @returns {string|null} The `data-component` attribute value or null if not found.
 */
export const getContainingComponent = (el) => {
	if (!el || el.nodeName?.toLowerCase() === 'body') return null;
	return (
		el.getAttribute('data-component') || getContainingComponent(el.parentNode)
	);
};

/**
 * Collects all `data-link-name` attribute values from the ancestor elements.
 *
 * @param {Element} el - The element to start searching from.
 * @param {string[]} dataLinkNames - Initial array of data link names (optional).
 * @returns {string[]} Array of `data-link-name` values.
 */
const getDataLinkNames = (el, dataLinkNames = []) => {
	if (!el || el === document.body || el === document) return dataLinkNames;

	const dataLinkName = el.getAttribute('data-link-name');
	if (dataLinkName) dataLinkNames.push(dataLinkName);

	return getDataLinkNames(el.parentNode, dataLinkNames);
};

if (typeof document.addEventListener === 'function') {
	document.addEventListener(
		'click',
		function (e) {
			let target = e.target;
			let info;

			// For anchors, use validAncestorAnchorElement
			const anchorTarget = validAncestorAnchorElement(target);

			const nonTrackableElements = [
				'div',
				'span',
				'p',
				'section',
				'article',
				'main',
				'header',
				'footer',
				'nav',
			];

			if (
				nonTrackableElements.includes(target.tagName.toLowerCase()) &&
				!anchorTarget
			) {
				return;
			}

			// If it's an anchor, use anchorTarget, otherwise use the original target
			const trackingTarget = anchorTarget || target;

			info = {
				from: [location.protocol, '//', location.host, location.pathname].join(''),
				to: trackingTarget.href || undefined,
				referringComponent: getContainingComponent(trackingTarget),
				referringDataLinkNames: getDataLinkNames(trackingTarget),
				refPlatform: ophan.servingPlatform(),
				refViewId: ophan.viewId,
			};

			if (info.referringDataLinkNames && info.referringDataLinkNames.length > 0) {
				transmit.sendMore({
					clickComponent: info.referringComponent,
					clickLinkNames: info.referringDataLinkNames,
				});
			}

			if (anchorTarget) {
				// If it's an anchor, we may be about to navigate away from this page, so store to send on next request
				return ophan.storeDataToSendOnNextEvent(info);
			}
		},
		false,
	);
}

export default {
	getDataLinkNames: getDataLinkNames,
};
