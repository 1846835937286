import clickCapture from './click-path-capture.js';

const validIframeDomains = [
	'.theguardian.com',
	'.dev-theguardian.com',
	'.thegulocal.com', // NB - leading dot is essential here to avoid matching on non-Guardian-owned domains
];

/**
 * Handles messages received from iframes, recording events as necessary.
 * @param {MessageEvent} messageEvent - The message event received from an iframe.
 */
const handleIframeMessage = (messageEvent) => {
	const originDomain = retrieveOriginDomain(messageEvent.origin);
	if (
		originDomain &&
		validIframeDomains.some((domain) => originDomain.endsWith(domain))
	) {
		if (messageEvent.data.type === 'ophan-iframe-click-event') {
			const eventObject = messageEvent.data.value;
			const iFrame = document.getElementById(messageEvent.data.iframeId);
			recordIframeClickEvent(iFrame, eventObject);
		} else if (messageEvent.data.type === 'ophan-iframe-component-event') {
			const eventObject = messageEvent.data.value;
			recordIframeComponentEvent(eventObject);
		}
	}
};

/**
 * Retrieves the domain from an event's origin URL.
 * @param {string} eventOrigin - The origin URL of the event.
 * @returns {(string|null)} The domain of the origin URL or null if it cannot be parsed.
 */
const retrieveOriginDomain = (eventOrigin) => {
	try {
		const originURL = new URL(eventOrigin);
		return originURL.host;
	} catch (error) {
		if (error instanceof TypeError) {
			// We failed to parse origin as an URL, we should ignore the event
			return null;
		} else {
			throw error;
		}
	}
};

/**
 * Records a click event occurring within an iframe.
 * @param {HTMLElement} iFrame - The iframe element where the click event occurred.
 * @param {Object} clickEvent - The click event object to record.
 */
const recordIframeClickEvent = (iFrame, clickEvent) => {
	if (iFrame) {
		clickEvent.clickLinkNames =
			clickCapture.getDataLinkNames(iFrame, clickEvent.clickLinkNames) ||
			clickEvent.clickLinkNames;
	}
	window.guardian.ophan.record(clickEvent);
};

/**
 * Validates if an event object is a valid component event.
 * @param {Object} eventObject - The event object to validate.
 * @returns {boolean} True if the event object is valid, false otherwise.
 */
const componentEventIsValid = (eventObject) => {
	return (
		eventObject &&
		typeof eventObject === 'object' &&
		eventObject.componentEvent &&
		typeof eventObject.componentEvent === 'object'
	);
};

/**
 * Records a component event from an iframe.
 * @param {Object} eventObject - The component event object to record.
 */
const recordIframeComponentEvent = (eventObject) => {
	if (!componentEventIsValid(eventObject)) {
		return;
	}
	window.guardian.ophan.record(eventObject);
};

window.addEventListener('message', handleIframeMessage, false);