import transmit from './transmit.js';

/*
	TO-DO:
	This function could probably be simplified with an async function.
	It basically returns a `boolean`, but uses a callback to do so... I can only guess it’s because of [`requestFileSystem`’s signature](https://developer.mozilla.org/en-US/docs/Web/API/Window/requestFileSystem).
 */
const privateBrowsingMode = (callback) => {
	const reportNormalMode = () => callback(false);
	const reportPrivateMode = () => callback(true);

	const tryLocalStorage = () => {
		try {
			if (localStorage.length) {
				reportNormalMode();
			} else {
				localStorage.x = 1;
				localStorage.removeItem('x');
				reportNormalMode();
			}
		} catch (error) {
			reportPrivateMode();
		}
	};

	const tryIndexedDB = () => {
		try {
			const db = indexedDB.open('test');
			db.onsuccess = reportNormalMode;
			db.onerror = reportPrivateMode;
		} catch (error) {
			reportPrivateMode();
		}
	};

	// Blink
	if (window.webkitRequestFileSystem) {
		window.webkitRequestFileSystem(
			window.TEMPORARY,
			1,
			reportNormalMode,
			reportPrivateMode,
		);
		// Firefox
	} else if ('MozAppearance' in document.documentElement.style) {
		tryIndexedDB();
		// Safari
	} else if (/constructor/i.test(window.HTMLElement)) {
		tryLocalStorage();
	} else if (
		!window.indexedDB &&
		(window.PointerEvent || window.MSPointerEvent)
	) {
		reportPrivateMode();
	} else {
		// Rest
		reportNormalMode();
	}
};

export default {
	init() {
		privateBrowsingMode((value) => {
			const report = {
				inPrivateBrowsingMode: value,
			};
			transmit.sendMore(report);
		});
	},
	privateBrowsingMode,
};
