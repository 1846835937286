// Generated by CoffeeScript 2.7.0
import transmit from './transmit.js';

import components from './components.js';

// The upworthy definition of attention minutes, from
// http://upworthy.github.io/2014/06/implementing-attention-minutes-part-1/ :

// "We consider the page to have the user’s attention if
//  1. some activity is happening on the page. For our purposes this means:
//     1. the page currently has focus
//     2. and the user has interacted with the page within a certain timeout
//  2. or a video is playing on the page
//  Attention Minutes, then, are the total number of minutes that a user has spent on
//  a page where the above conditions have held true."

// our definition of "within a certain timeout" above:
const ATTENTIONDECAY = 5000;

// our definition of "the user has interacted with the page"
const EVENTS = [
	'focus',
	'click',
	'scroll',
	'mousemove',
	'touchstart',
	'touchend',
	'touchcancel',
	'touchleave',
	'touchmove',
	'keyup',
	'keydown',
];

const REPORTINGINTERVAL = 10000;

// total attention time so far on this page
let totalAttentionMs = 0;

// the time elapsed since the time origin (https://developer.mozilla.org/en-US/docs/Web/API/DOMHighResTimeStamp#the_time_origin)
// when the most recent period of attention (that is, attention
// which we haven't yet added to totalAttentionMs) began.
// null if we don't have attention.
let unrecordedAttentionStarted = null;

// the attention time we last reported to ophan
let reportedTotalAttentionMs = null; // setting null here forces a first report of attention time, even if it's only 0ms

// the decay timer if we've set one
let decayTimerId = null;

// is video currently playing?
let videoPlaying = false;

const cancelDecayTimer = () => {
	if (decayTimerId != null) {
		window.clearTimeout(decayTimerId);
	}
	decayTimerId = null;
};

const makeActive = () => {
	if (unrecordedAttentionStarted == null) {
		unrecordedAttentionStarted = performance.now();
	}
	components.startMonitoring();
	cancelDecayTimer();
	decayTimerId = window.setTimeout(() => {
		if (!videoPlaying) {
			makeInactive();
		}
	}, ATTENTIONDECAY);
};

const incrementTotalAttentionTimeByUnrecordedAmount = () => {
	if (unrecordedAttentionStarted != null) {
		const now = performance.now();
		const unrecordedMs = Math.min(
			now - unrecordedAttentionStarted,
			REPORTINGINTERVAL,
		);
		totalAttentionMs += unrecordedMs;
		unrecordedAttentionStarted = now;
	}
};

const makeInactive = () => {
	cancelDecayTimer();
	components.stopMonitoring();
	incrementTotalAttentionTimeByUnrecordedAmount();
	unrecordedAttentionStarted = null;
};

const reporter = () => {
	incrementTotalAttentionTimeByUnrecordedAmount();
	if (totalAttentionMs !== reportedTotalAttentionMs) {
		const report = {
			attentionMs: Math.round(totalAttentionMs),
		};
		const componentAttentionTimes = components.getAttentionTimes();
		if (Object.keys(componentAttentionTimes).length) {
			report.componentAttentionMs = componentAttentionTimes;
		}
		transmit.sendMore(report);
		reportedTotalAttentionMs = totalAttentionMs;
	}
};

const initComponent = (name, el, visibilityThreshold = 0.5) => {
	components.registerComponent(
		name,
		el,
		visibilityThreshold,
		REPORTINGINTERVAL,
	);
};

const initAttention = (visibility) => {
	EVENTS.forEach((event) => {
		window.addEventListener(event, makeActive);
	});

	document.addEventListener(
		visibility.changeEvent,
		() => {
			if (visibility.state() === 'visible') {
				makeActive();
			} else {
				makeInactive();
			}
		},
		false,
	);

	document.addEventListener('videoPlaying', () => {
		videoPlaying = true;
		makeActive();
	});
	document.addEventListener('videoEnded', () => {
		videoPlaying = false;
		makeInactive();
	});
	document.addEventListener('videoPause', () => {
		videoPlaying = false;
		makeInactive();
	});

	window.setTimeout(reporter, 100);
	window.setInterval(reporter, REPORTINGINTERVAL);
};

const setEventEmitter = (emitter) => {
	components.setEventEmitter(emitter);
};

export default {
	init: initAttention,
	initComponent: initComponent,
	setEventEmitter: setEventEmitter,
};
