// Generated by CoffeeScript 2.7.0
import core from './core.js';

const perf =
	window.performance ||
	window.msPerformance ||
	window.webkitPerformance ||
	window.mozPerformance;

const sendTimingData = () => {
	const t = perf?.timing;
	if (t) {
		return {
			performance: {
				// Time required for domain lookup.
				dns: t.domainLookupEnd - t.domainLookupStart,
				// Time to establish a connection to server.
				connection: t.connectEnd - t.connectStart,
				// From connection established to first byte of data.
				firstByte: t.responseStart - t.connectEnd,
				// First byte to last byte, or closed, including if from cache.
				lastByte: t.responseEnd - t.responseStart,
				// From last byte of doc to start of domContentLoaded
				domContentLoadedEvent: t.domContentLoadedEventStart - t.responseEnd,
				// domcontentLoaded to start of load event.
				loadEvent: t.loadEventStart - t.domContentLoadedEventStart,
				// click, back/forward, etc...
				navType: perf.navigation.type,
				// No. of redirects on current domain.
				redirectCount: perf.navigation.redirectCount,
			},
		};
	} else {
		return {};
	}
};

core.onLoadCapture(sendTimingData);

export default perf;