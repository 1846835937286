/**
 * @type {Component[]}
 */
export const components = [];

/**
 * theguardian.com uses a custom scroll event for performance reasons.
 * We pass in the event emitter so that we can listen to this custom scroll event.
 * If not on theguardian.com, we default to the native scroll event
 * @type {Object|null}
 */
let eventEmitter = null;

// Tracks attention time of elements on the page with data-component attributes.
// This should always be less than or equal to the page attention time.
// If the page does not have attention, no component has attention.
// If the page does have attention, a given component *may* also have
// attention, if it is within the viewport and not hidden.
class Component {
	/**
	 * Component constructor.
	 * @param {string} name - The name of the component.
	 * @param {HTMLElement} element - The DOM element of the component.
	 * @param {number} visibilityThreshold1 -
	 *    visibilityThreshold represents the fraction of each component that must
	 *    be in the viewport before it is considered visible.
	 *    e.g. 0.5 means that half the height or width must be in the viewport
	 *    1 means that the entire element must be in the viewport
	 * @param {number} reportingInterval1 - ??
	 */
	constructor(name, element, visibilityThreshold1, reportingInterval1) {
		this.name = name;
		this.element = element;
		this.visibilityThreshold = visibilityThreshold1;
		this.reportingInterval = reportingInterval1;
		this.visible = false;
		// total attention time so far for this element
		this.totalAttentionMs = 0;
		// the time elapsed since the time origin (https://developer.mozilla.org/en-US/docs/Web/API/DOMHighResTimeStamp#the_time_origin)
		// when the most recent period of attention (that is, attention
		// which we haven't yet added to totalAttentionMs) began.
		// null if we don't have attention.
		this.unrecordedAttentionStarted = null;
		// the attention time when getAttentionTime() was last called
		this.reportedTotalAttentionMs = 0;
		this.usingEmitter = false;
		this.visCheck = this.checkVisibility.bind(this);
		if (eventEmitter != null) {
			this.usingEmitter = true;
			eventEmitter.on('window:throttledScroll', this.visCheck);
		} else {
			window.addEventListener('scroll', this.visCheck);
		}
		window.addEventListener('resize', this.visCheck);
	}

	isVisible(threshold = 1) {
		if (!this.element.offsetParent) {
			// exclude hidden elements
			// will also exclude position: fixed elements but we don't care about these
			// (because their attention time would always be the same as the whole page)
			// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
			return false;
		}

		const box = this.element.getBoundingClientRect();
		const width = box.width;
		const height = box.height;
		const windowHeight =
			window.innerHeight || document.documentElement.clientHeight;
		const windowWidth =
			window.innerWidth || document.documentElement.clientWidth;

		return (
			box.left >= -(width * (1 - threshold)) &&
			box.top >= -(height * (1 - threshold)) &&
			box.right <= windowWidth + width * (1 - threshold) &&
			box.bottom <= windowHeight + height * (1 - threshold)
		);
	}

	visibilityHasChanged() {
		const wasVisible = this.visible;
		this.visible = this.isVisible(this.visibilityThreshold);
		return wasVisible !== this.visible;
	}

	rebindToEventEmitter() {
		if (!this.usingEmitter && eventEmitter != null) {
			window.removeEventListener('scroll', this.visCheck);
			this.usingEmitter = true;
			return eventEmitter.on('window:throttledScroll', this.visCheck);
		}
	}

	checkVisibility() {
		this.rebindToEventEmitter();
		if (this.visibilityHasChanged()) {
			return this.visible ? this.makeActive() : this.makeInactive();
		}
	}

	makeActive() {
		// mark when the latest period of attention began,
		// if we're not already in an attention period
		return this.unrecordedAttentionStarted != null
			? this.unrecordedAttentionStarted
			: (this.unrecordedAttentionStarted = performance.now());
	}

	makeInactive() {
		this.incrementTotalAttentionTimeByUnrecordedAmount();
		return (this.unrecordedAttentionStarted = null);
	}

	hadAttentionSinceLastGet() {
		this.incrementTotalAttentionTimeByUnrecordedAmount();
		return this.totalAttentionMs !== this.reportedTotalAttentionMs;
	}

	getAttentionTime() {
		this.incrementTotalAttentionTimeByUnrecordedAmount();
		this.reportedTotalAttentionMs = this.totalAttentionMs;
		return this.totalAttentionMs;
	}

	incrementTotalAttentionTimeByUnrecordedAmount() {
		if (this.unrecordedAttentionStarted != null) {
			const now = performance.now();
			// the Math.min here is to deal with occasions where we don't get an event indicating that the user
			// has become inactive - this can happen e.g. due to users suspending their phone. Never send
			// crazy big values!
			const unrecordedMs = Math.min(
				now - this.unrecordedAttentionStarted,
				this.reportingInterval,
			);
			this.totalAttentionMs += unrecordedMs;
			return (this.unrecordedAttentionStarted = now);
		}
	}
}

/**
 * Begins monitoring the components on the page for attention time.
 * @returns {Array} Array of results from makeActive() for each visible component.
 */
const startMonitoring = () => {
	return components
		.filter((component) => component.isVisible())
		.map((component) => component.makeActive());
};

/**
 * Stops monitoring the components on the page for attention time.
 * @returns {Array} Array of results from makeInactive() for each component.
 */
const stopMonitoring = () => {
	return components.map((component) => component.makeInactive());
};

/**
 * Retrieves the attention times for all components.
 * @returns {Object}
 */
const getAttentionTimes = () => {
	const obj = {};
	for (const component of components) {
		if (component.hadAttentionSinceLastGet()) {
			// if there are duplicate component names, only the last one survives
			obj[component.name] = Math.round(component.getAttentionTime());
		}
	}
	return obj;
};

export default {
	/**
	 * @param {Object} emitter - The event emitter to set.
	 * @returns {Object}
	 */
	setEventEmitter: function (emitter) {
		return (eventEmitter = emitter);
	},
	startMonitoring,
	stopMonitoring,
	getAttentionTimes,

	/** @type {(...args: ConstructorParameters<typeof Component>) => void} */
	registerComponent: function (
		name,
		el,
		visibilityThreshold,
		reportingInterval,
	) {
		return components.push(
			new Component(name, el, visibilityThreshold, reportingInterval),
		);
	},
};