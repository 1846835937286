let heatmapFired = false;

const init =  () => {
	const addHeatmap =  () => {
		let heatmapParam;
		if (
			typeof localStorage !== 'undefined' &&
			localStorage !== null &&
			!heatmapFired
		) {
			heatmapFired = true;
			heatmapParam = window.location.search.replace(
				/^(?:.*[&\?]heatmap(?:\=([^&]*))?)?.*$/,
				'$1',
			);

			if (heatmapParam === 'experimental') {
				return (document.body.appendChild(
					document.createElement('script'),
				).src = '//dashboard.ophan.co.uk/assets/js/react/heatphan/index.js');
			}

			if (heatmapParam === 'true' || heatmapParam === 'false') {
				localStorage['ophan_heatmap'] = heatmapParam;
			}

			if (localStorage['ophan_heatmap'] === 'true' || heatmapParam === 'show') {
				return (document.body.appendChild(
					document.createElement('script'),
				).src = '//dashboard.ophan.co.uk/assets/js/heatmap-bookmarklet.js');
			}

		}
	};

	if (document.readyState === 'complete') {
		return addHeatmap();
	} else {
		return typeof window.addEventListener === 'function'
			? window.addEventListener(
					'load',
					function () {
						return addHeatmap();
					},
					false,
				)
			: void 0;
	}
};

export default {
	init,
};
